<template>
    <div class="custom-btn-group" :style="[{ gap: gap }]" role="group" aria-label="Custom radio toggle button group">
        <div v-for="(option, index) in options" :key="`${name}-custom-btn-${index}`">
            <input type="radio" class="custom-btn-check" :name="name" :id="`${name}-btn-${index}`" :value="option.value"
                v-model="selectedValue" autocomplete="off" />
            <label :for="`${name}-btn-${index}`" class="custom-btn custom-btn-outline" :class="[
                `custom-btn-${option.type}`,
                selectedValue === option.value ? 'custom-btn-selected' : ''
            ]" :style="[{ padding: padding }]">
                {{ option.label }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomRadioButtonGroup',
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        padding: {
            type: String,
            default: '10px 20px',
        },
        gap: {
            type: String,
            default: '10px',
        },
        value: {
            type: [String, Number],
            default: '',
        },
    },
    data() {
        return {
            selectedValue: this.value,
        };
    },
    watch: {
        value(newVal) {
            this.selectedValue = newVal;
        },
        selectedValue(newVal) {
            this.$emit('input-change', { value: newVal, name: this.name });
        },
    },
};
</script>

<style scoped>
.custom-btn-group {
    display: flex;
    justify-content: center;
}

.custom-btn-check {
    display: none;
}

.custom-btn {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.3s ease;
    background-color: transparent;
}

.custom-btn-outline {
    color: #007bff;
    border-color: #007bff;
}

.custom-btn-primary {
    color: #007bff;
    border-color: #007bff;
}

.custom-btn-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.custom-btn-success {
    color: #28a745;
    border-color: #28a745;
}

.custom-btn-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.custom-btn-warning {
    color: #ffc107;
    border-color: #ffc107;
}

.custom-btn-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.custom-btn-check:checked+.custom-btn {
    color: white;
    border-color: transparent;
}

.custom-btn-check:checked+.custom-btn.custom-btn-primary {
    background-color: #007bff;
}

.custom-btn-check:checked+.custom-btn.custom-btn-secondary {
    background-color: #6c757d;
}

.custom-btn-check:checked+.custom-btn.custom-btn-success {
    background-color: #28a745;
}

.custom-btn-check:checked+.custom-btn.custom-btn-danger {
    background-color: #dc3545;
}

.custom-btn-check:checked+.custom-btn.custom-btn-warning {
    background-color: #ffc107;
}

.custom-btn-check:checked+.custom-btn.custom-btn-info {
    background-color: #17a2b8;
}
</style>
