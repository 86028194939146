<template>
    <div>
        <template v-if="invoice_amount == booking_cost">
            <span class="custom-badge custom-badge-success">Matches with the System</span>
        </template>
        <template v-else-if="invoice_amount > booking_cost">
            <span class="custom-badge custom-badge-danger">Inv Amt > Booking Cost</span>
        </template>
        <template v-else-if="invoice_amount < booking_cost">
            <span class="custom-badge custom-badge-danger">Inv Amt &lt; Booking Cost</span>
        </template>
    </div>
</template>

<script>

export default {
    name: "ReconStatus",
    props: {
        invoice_amount: {
            required: true
        },
        booking_cost: {
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    background-color: #007bff;
    border-radius: 0.25rem;
}

.custom-badge-secondary {
    background-color: #6c757d;
}

.custom-badge-success {
    background-color: #28a745;
}

.custom-badge-danger {
    background-color: #dc3545;
}

.custom-badge-warning {
    background-color: #ffc107;
}

.custom-badge-info {
    background-color: #17a2b8;
}
</style>