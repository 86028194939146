<template>
    <div>
        <input type="checkbox" :id="name" class="custom-btn-check" @change="handleChange">
        <label :for="name" :class="`custom-btn custom-btn-${type}`" :style="[{ padding: padding }]" checked>{{ label }}</label>
    </div>

</template>

<script>
export default {
    name: 'CheckboxButton',
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
            validator(value) {
                return value.length === 2;
            }
        },
        padding: {
            type: String,
            default: '10px 20px',
        },
        value: {
            type: [String, Number, Boolean],
            default: '',
        },
    },
    computed: {
        label() {
            const selected_option = this.options.find((item) => item.value !== this.value);
            return selected_option ? selected_option.label : '';
        },
        type() {
            const selected_option = this.options.find((item) => item.value !== this.value);
            return selected_option ? selected_option.type : '';
        }
    },
    methods: {
        handleChange() {
            this.$emit('checkbox-change', !this.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-btn-check {
    display: none;
}

.custom-btn {
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    transition: all 0.3s ease;
}

.custom-btn-primary {
    background-color: #007bff;
}

.custom-btn-secondary {
    background-color: #6c757d;
}

.custom-btn-success {
    background-color: #28a745;
}

.custom-btn-danger {
    background-color: #dc3545;
}

.custom-btn-warning {
    background-color: #ffc107;
}

.custom-btn-info {
    background-color: #17a2b8;
}
</style>

