<template>
    <div class="table-responsive">
        <table class="custom-table">
            <thead>
                <tr>
                    <th rowspan="2">Files</th>
                    <th rowspan="2">Status</th>
                    <th colspan="2">Uploaded</th>
                    <th colspan="3">Reconciliation</th>
                </tr>
                <tr>
                    <th>User</th>
                    <th>Date Time</th>
                    <th>User</th>
                    <th>Date Time</th>
                    <th>Inclusion Status</th>
                </tr>
            </thead>

            <tbody>
                <template v-if="history_data.length > 0">
                    <tr v-for="(history, h) in history_data">
                        <td>
                            <template
                                v-if="history.invoice_data.uploaded_file && history.invoice_data.uploaded_file.files">
                                <p v-for="(file, f) in history.invoice_data.uploaded_file.files">
                                    {{ file.file_name }}
                                </p>
                            </template>
                        </td>
                        <td>
                            <Badge
                                :type="history.invoice_data.uploaded_file.task.status == 'reconciled' ? 'success' : 'info'">
                                {{
                                    capitalizeFirstLetter(history.invoice_data.uploaded_file.task.status)
                                }}</Badge>
                        </td>
                        <td>
                            {{ history.invoice_data.uploaded_file.task.uploaded_by.name }}
                        </td>
                        <td>{{ history.invoice_data.uploaded_file.task.created_at | formatDateTime }}</td>
                        <template v-if="history.invoice_data.uploaded_file.task.status == 'reconciled'">
                            <td>{{ history.invoice_data.reconciled_by.name }}</td>
                            <td>{{ history.invoice_data.reconciled_at | formatDateTime }}</td>
                            <td>
                                <Badge :type="history.invoice_data.is_included ? 'success' : 'danger'">{{ history.invoice_data.is_included ? 'Included' : 'Dropped' }}</Badge>
                            </td>
                        </template>
                        <template v-else>
                            <td colspan="3">
                                <Badge type="danger">No Reconciliation Data</Badge>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td>No Invoice Data Found</td>
                    </tr>
                </template>
            </tbody>
        </table>

    </div>
</template>
<script>
import moment from "moment";
import ReconStatus from "./ReconStatus.vue";
import ActionButton from "../components/ActionButton.vue";
import Badge from "../components/Badge.vue";

export default {
    name: "DuplicatedBookingHistory",
    props: {
        history_data: {
            required: true
        },
    },
    components: {
        ReconStatus,
        ActionButton,
        Badge
    },
    filters: {
        formatDate(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('YYYY/MM/DD');
        },
        formatTime(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('HH:mm');
        },
        formatDateTime(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('YYYY/MM/DD HH:mm');
        }
    },
    methods: {
        capitalizeFirstLetter(string) {
            if (!string) return string;

            return string
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
    }
};
</script>

<style lang="scss" scoped>
// custome-table
.table-responsive {
    overflow-x: auto;
    margin: 20px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table .error_row {
    background-color: #f8d7da !important;
}

.custom-table .reconciled td {
    background-color: #d1e7dd !important;
    border-color: #A7B99B !important;
}

.custom-table th,
.custom-table td {
    padding-inline: 5px;
    padding-block: 12px;
    border: 1px solid #dee2e6;
    text-align: center;
    white-space: nowrap;
    font-size: 15px;
}

.custom-table th {
    background-color: #f8f9fa;
    color: #495057;
}

.custom-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.custom-table tr:hover {
    background-color: #e9ecef;
}

.row-checkbox {
    cursor: pointer;
}

.custom-table .action-btns>.custom-action-button {
    margin-right: 5px;
}

.custom-table .action-btns>.custom-action-button:last-child {
    margin-right: 0 !important;
}
</style>