<template>
    <div>
        <SectionLoader v-if="loader.is_loading" />
        <form class="custom-form" @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="name">Type</label>
                <div class="custom-select-wrapper">
                    <select class="form-control custom-select" v-model="selected_type" @change="changeType($event)">
                        <option v-for="type in types">{{ type }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="name">{{ `${['Matter Number', 'Gl Code'].includes(selected_type) ? 'Select' : 'Enter'}
                    ${selected_type}` }}</label>
                <template v-if="selected_type == 'Matter Number'">
                    <select-dropdown :id="value" :items="matter_numbers"
                        @setSelected="setSelectedMatterNumber"></select-dropdown>
                </template>
                <template v-else-if="selected_type == 'Gl Code'">
                    <div class="dropdown-container">
                        <div v-for="(dropdown, dd) in gl_code_dropdowns" :key="`dropdown-${dd}`">
                            <label :for="dd" class="gl-code-labels">{{ dropdown.label }} <span
                                    class="required">*</span></label>
                            <select-dropdown :items="dropdown.options" :id="dropdown.selected"
                                @setSelected="dropdown.callback" :ref="`${dd}-select`">
                            </select-dropdown>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <input type="text" class="form-control" v-model="value">
                </template>
            </div>

            <div class="custom-btn-wrapper">
                <button type="submit" :class="['btn', 'btn-primary', { 'disabled': button.disabled }]"
                    :disabled="button.disabled">{{ button.text }}</button>
            </div>

        </form>
    </div>
</template>

<script>
import SectionLoader from './SectionLoader.vue';

export default {
    name: "CostCenterManager",
    components: {
        SectionLoader
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            button: {
                text: 'Add',
                disabled: false
            },
            selected_type: null,
            loader: {
                text: "hellow",
                is_loading: false
            },
            value: null,
            types: ["Matter Number", "Gl Code", "Other Management Code", "PAL Code"],
            companies_mapping: {},
            gl_code_dropdowns: {
                companies: {
                    options: [],
                    label: 'Company',
                    callback: this.setSelectedCompany,
                    selected: 0,
                },
                offices: {
                    options: [],
                    label: 'Office',
                    callback: this.setSelectedOffice,
                    selected: 0,
                },
                entities: {
                    options: [],
                    label: 'Entity',
                    callback: this.setSelectedEntity,
                    selected: 0,
                },
                departments: {
                    options: [],
                    label: 'Department',
                    callback: this.setSelectedDepartment,
                    selected: 0,
                },
                sections: {
                    options: [],
                    label: 'Section',
                    callback: this.setSelectedSection,
                    selected: 0,
                },
            },
        }
    },
    async created() {

        this.loader.is_loading = true;

        this.resetSelection(['companies', 'offices', 'entities', 'departments', 'sections']);
        await Promise.all([this.fetchMatterNumber(), this.fetchGlCodesCompanies()]);


        this.selected_type = this.data.custom_code || 'Matter Number';
        this.value = this.data.custom_code_value || 0;

        if (this.selected_type === 'Matter Number' && !this.data.custom_code_value) {
            this.value = 0;
        }

        if (this.selected_type == 'Gl Code' && this.value) {
            await this.populateGlCode(this.value);

            this.button.text = 'Change';
        }

        this.loader.is_loading = false;
    },
    methods: {
        async fetchMatterNumber() {
            try {
                let localMatterNumbers = localStorage.getItem('matter_numbers');

                if (localMatterNumbers) {
                    localMatterNumbers = JSON.parse(localMatterNumbers);
                    if (localMatterNumbers.length > 0) {
                        this.matter_numbers = localMatterNumbers;
                        this.value = 0;
                        return;
                    }
                }

                const { data } = await this.axios.get('/api/mater-numbers');

                if (data && data.matter_number) {
                    this.matter_numbers = [
                        { id: 0, label: 'No Matter Number' },
                        ...data.matter_number.map(matterNumber => ({
                            id: matterNumber.matter_number,
                            label: matterNumber.matter_number,
                        }))
                    ];

                    localStorage.setItem('matter_numbers', JSON.stringify(this.matter_numbers));
                }
            }
            catch (err) {
                console.log(err);
            }
        },
        async fetchOptions(apiUrl, target, defaultLabel) {
            try {
                const response = await this.axios.get(apiUrl);
                if (response) {
                    this.gl_code_dropdowns[target].options.splice(0, this.gl_code_dropdowns[target].options.length);
                    this.gl_code_dropdowns[target].options.push({ id: '', label: defaultLabel });

                    var data = response.data[target];

                    if (target === 'companies') {
                        data = this.convertCompaniesData(data);
                    }

                    if (data.length > 0) {

                        data.forEach(item => {
                            this.gl_code_dropdowns[target].options.push({
                                id: item.code,
                                label: `${target != 'companies' ? item.code + ' - ' : ''} ${item.name}`,
                            });
                        });
                    }

                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchGlCodesCompanies() {
            await this.fetchOptions('/api/gl-code-companies', 'companies', 'Select Company');
        },
        async setSelectedCompany(value) {
            if (value && typeof value.id !== 'undefined' && value.id !== '') {
                this.loader.is_loading = true;
                this.resetSelection(['offices', 'entities', 'departments', 'sections']);
                this.gl_code_dropdowns['companies'].selected = value.id;

                const company_id = this.companies_mapping[this.gl_code_dropdowns['companies'].selected];

                await this.fetchOptions(`/api/gl-code-company-offices/${company_id}`, 'offices', 'Select Office');

                this.gl_code_dropdowns['offices'].selected = 0;
                this.gl_code_dropdowns['entities'].selected = 0;
                this.gl_code_dropdowns['departments'].selected = 0;
                this.gl_code_dropdowns['sections'].selected = 0;
                this.value = null;
                this.loader.is_loading = false;
            }
        },
        async setSelectedOffice(value) {
            if (value && typeof value.id !== 'undefined' && value.id !== '') {
                this.loader.is_loading = true;
                this.resetSelection(['entities', 'departments', 'sections']);
                this.gl_code_dropdowns['offices'].selected = value.id;

                await this.fetchOptions(`/api/gl-code-office-entities/${value.id}`, 'entities', 'Select Entity');

                this.gl_code_dropdowns['entities'].selected = 0;
                this.gl_code_dropdowns['departments'].selected = 0;
                this.gl_code_dropdowns['sections'].selected = 0;
                this.value = null;
                this.loader.is_loading = false;
            }
        },
        async setSelectedEntity(value) {
            if (value && typeof value.id !== 'undefined' && value.id !== '') {
                this.loader.is_loading = true;

                this.resetSelection(['departments', 'sections']);
                this.gl_code_dropdowns['entities'].selected = value.id;

                await this.fetchOptions(`/api/gl-code-entity-departments/${value.id}`, 'departments', 'Select Department');

                this.gl_code_dropdowns['departments'].selected = 0;
                this.gl_code_dropdowns['sections'].selected = 0;
                this.value = null;
                this.loader.is_loading = false;
            }
        },
        async setSelectedDepartment(value) {
            if (value && typeof value.id !== 'undefined' && value.id !== '') {
                this.loader.is_loading = true;
                this.resetSelection(['sections']);
                this.gl_code_dropdowns['departments'].selected = value.id;

                await this.fetchOptions(`/api/gl-code-department-sections/${value.id}`, 'sections', 'Select Section');

                this.gl_code_dropdowns['sections'].selected = 0;
                this.value = null;
                this.loader.is_loading = false;
            }
        },
        setSelectedSection(value) {
            if (value && typeof value.id !== 'undefined' && value.id !== '') {
                this.gl_code_dropdowns['sections'].selected = value.id;
                this.value = `${this.gl_code_dropdowns['sections'].selected}-${this.gl_code_dropdowns['departments'].selected}-${this.gl_code_dropdowns['offices'].selected}-${this.gl_code_dropdowns['entities'].selected}-000`;
            }
        },
        resetSelection(arrays) {
            arrays.forEach(key => {
                if (this.gl_code_dropdowns[key]) {
                    this.gl_code_dropdowns[key].options = [{ id: '', label: '' }];
                    this.gl_code_dropdowns[key].selected = 0;
                }
            });
        },
        changeType(e) {

            const selected_type = e.target.value;

            if (selected_type == 'Matter Number') {
                this.value = 0;
                return;
            }

            this.value = '';
        },
        convertCompaniesData(data) {

            return data.map((item) => {
                const code = this.convertToKey(item.name);

                this.companies_mapping[code] = item.code;

                return {
                    ...item,
                    code: code
                }
            });
        },
        getCompanyKeyValue(valueToCheck) {
            for (const [key, value] of Object.entries(this.companies_mapping)) {
                if (value === valueToCheck) {
                    return key;
                }
            }
            return null;
        },
        convertToKey(value) {
            return value
                .toLowerCase()
                .replace(/[\s&]+/g, '_')
                .replace(/[^\w-]+/g, '')
                .replace(/--+/g, '_');
        },
        setSelectedMatterNumber(selected) {
            if (typeof selected !== 'undefined' && selected != null) {
                this.value = typeof selected.id !== 'undefined' ? selected.id : selected;
            }
        },
        async populateGlCode(gl_code) {
            const gl_code_arr = gl_code.split('-');

            this.gl_code_dropdowns['companies'].selected = this.getCompanyKeyValue(0);

            const company_id = this.companies_mapping[this.gl_code_dropdowns['companies'].selected];

            await this.fetchOptions(`/api/gl-code-company-offices/${company_id}`, 'offices', 'Select Office');
            this.gl_code_dropdowns['offices'].selected = gl_code_arr[2];

            await this.fetchOptions(`/api/gl-code-office-entities/${gl_code_arr[2]}`, 'entities', 'Select Entity');
            this.gl_code_dropdowns['entities'].selected = gl_code_arr[3];

            await this.fetchOptions(`/api/gl-code-entity-departments/${gl_code_arr[3]}`, 'departments', 'Select Department');
            this.gl_code_dropdowns['departments'].selected = gl_code_arr[1];

            await this.fetchOptions(`/api/gl-code-department-sections/${gl_code_arr[1]}`, 'sections', 'Select Section');
            this.gl_code_dropdowns['sections'].selected = gl_code_arr[0];

        },
        handleSubmit() {
            if (this.selected_type == 'Matter Number' && (this.value == 0 || this.value == '' || this.value == null)) {
                this.toast.error(`Please select Matter Number`);
                return;
            }

            if (this.selected_type == 'Gl Code' && (this.value == '' || this.value == null)) {
                this.toast.error(`Please select Gl Code`);
                return;
            }

            if (['Other Management Code', 'PAL Code'].includes(this.selected_type) && (this.value == '' || this.value == null)) {
                this.toast.error(`Please enter ${this.selected_type}}`);
                return;
            }

            this.$emit('update-cost-center-data', {
                ...this.data,
                custom_code: this.selected_type,
                custom_code_value: this.value,
            });
        }

    }
};
</script>

<style lang="scss" scoped>
.required {
    color: red;
}

/* custom form */
.custom-form {
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 600px;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #495057;
}

.form-control {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

/* custom file upload */

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    // max-width: 400px;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    border: 1px solid #ced4da;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-file-input:disabled+.custom-file-label {
    background-color: #e9ecef;
    color: #495057;
    cursor: not-allowed;
}

.custom-file-label::after {
    content: "Browse";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    padding: 0.375rem 0.75rem;
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
}

/* Custom Select Dropdown */
.custom-select-wrapper {
    width: 100%;
    // max-width: 400px;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: auto;
}

.custom-select-wrapper::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #495057;
}

.custom-select-wrapper select:disabled {
    background-color: #e9ecef;
    color: #6c757d;
}

// custom btn
.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    line-height: 50px;
    color: #fff;
    background-color: #004a7c;
    width: 200px;
    height: 50px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.custom-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

// GL CODE 
.dropdown-container {
    display: grid;
    gap: 10px;
    padding-inline: 1.5rem;
    padding-top: 1rem;
}

.gl-code-labels {
    color: #000 !important;
}
</style>