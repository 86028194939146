<template>
    <div>
        <SectionLoader v-if="loader.is_loading" />
        <form class="custom-form" @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="description">Comment <span class="required">*</span></label>
                <textarea id="description" v-model="value" class="custom-textarea" required
                    :maxlength="maxlength"></textarea>
                <div class="character-counter">
                    {{ characterCount }}/{{ maxlength }} characters
                </div>
            </div>

            <div class="custom-btn-wrapper">
                <button type="submit" :class="['btn', 'btn-primary', { 'disabled': button.disabled }]"
                    :disabled="button.disabled">{{ button.text }}</button>
            </div>

        </form>
    </div>
</template>

<script>
import SectionLoader from './SectionLoader.vue';

export default {
    name: "CommentForm",
    components: {
        SectionLoader
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        maxlength: {
            type: Number,
            default: 250
        }
    },
    data() {
        return {
            button: {
                text: 'Add',
                disabled: false
            },
            loader: {
                text: "hellow",
                is_loading: false
            },
            value: null,
            characterCount: 0,
        }
    },
    async created() {

        this.loader.is_loading = true;

        this.value = this.data.comment || '';

        this.button.text = this.data.comment ? 'Change' : 'Add'

        this.loader.is_loading = false;
    },
    watch: {
        value(newValue, oldValue) {
            this.characterCount = newValue.length;
        }
    },
    methods: {
        handleSubmit() {
            if (this.value == '' || this.value == null) {
                this.toast.error(`Please enter comment`);
                return;
            }

            const regex = /^[^$%^&*ñ()_+":~`<>;#]+$/;

            if (!regex.test(this.value)) {
                this.toast.error(`Comment format invalid.`);
                return;
            }

            this.$emit('update-comment-data', {
                ...this.data,
                comment: this.value,
            });
        }

    }
};
</script>

<style lang="scss" scoped>
.required {
    color: red;
}

.custom-form {
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 600px;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #495057;
}

.form-control {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

.character-counter {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #6c757d;
    text-align: end;
}

/* Custom textarea styles */
.custom-textarea {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    resize: vertical;
    min-height: 100px;
}

/* Disabled state for the custom text area */
.custom-textarea:disabled {
    background-color: #e9ecef;
    color: #495057;
}

.custom-file-input:disabled+.custom-file-label {
    background-color: #e9ecef;
    color: #495057;
    cursor: not-allowed;
}

.custom-file-label::after {
    content: "Browse";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    padding: 0.375rem 0.75rem;
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
}

.btn {
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    line-height: 50px;
    color: #fff;
    background-color: #004a7c;
    width: 200px;
    height: 50px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.custom-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
